'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.Orientation =
  exports.OfferStatus =
  exports.BidderStatus =
  exports.NewButtonSizes =
  exports.NewButtonTypes =
  exports.SemanticButtonTypes =
  exports.SECTIONS =
  exports.REALM_URLS =
  exports.PAGES =
  exports.HOMEPAGE_URLS =
  exports.VT_LISTING_PAGE_BASE_URLS =
  exports.LISTING_PAGE_BASE_URLS =
  exports.AGENT_OFFERS =
  exports.VT_SEARCH_URLS_V2 =
  exports.VT_SEARCH_URLS =
  exports.SEARCH_URLS =
  exports.SellerType =
  exports.FeaturedLevelFullToRanking =
  exports.AdFeatureLevelFull =
  exports.AdFeatureLevel =
  exports.ImageSizes =
  exports.PriceHistoryIndicatorDirection =
  exports.BerRating =
  exports.CSSTransitionType =
  exports.PropertyCategories =
  exports.AdDimensions =
    void 0;
var AdDimensions;
(function (AdDimensions) {
  AdDimensions['MPU_WIDTH'] = '300px';
  AdDimensions['MPU_HEIGHT'] = '250px';
  AdDimensions['SKYSCRAPER_WIDTH'] = '160px';
  AdDimensions['SKYSCRAPER_HEIGHT'] = '600px';
  AdDimensions['LEADERBOARD_WIDTH'] = '720px';
  AdDimensions['LEADERBOARD_HEIGHT'] = '90px';
  AdDimensions['STANDARD_BANNER_WIDTH'] = '468px';
  AdDimensions['STANDARD_BANNER_HEIGHT'] = '60px';
  AdDimensions['TILE_WIDTH'] = '168px';
  AdDimensions['TILE_HEIGHT'] = '60px';
})(AdDimensions || (exports.AdDimensions = AdDimensions = {}));
var PropertyCategories;
(function (PropertyCategories) {
  PropertyCategories['SALES'] = 'SALES';
  PropertyCategories['SHARING'] = 'SHARING';
  PropertyCategories['NEW_DEVELOPMENT'] = 'NEW_DEVELOPMENT';
  PropertyCategories['COMMERCIAL_SALES'] = 'COMMERCIAL_SALES';
  PropertyCategories['COMMERCIAL_RENT'] = 'COMMERCIAL_RENT';
  PropertyCategories['NEW_HOMES'] = 'New Homes';
  PropertyCategories['BUY'] = 'Buy';
  PropertyCategories['RENT'] = 'Rent';
})(
  PropertyCategories || (exports.PropertyCategories = PropertyCategories = {}),
);
var CSSTransitionType;
(function (CSSTransitionType) {
  CSSTransitionType['EASE'] = 'ease';
  CSSTransitionType['LINEAR'] = 'linear';
  CSSTransitionType['EASEIN'] = 'ease-in';
  CSSTransitionType['EASEOUT'] = 'ease-out';
  CSSTransitionType['EASEINOUT'] = 'ease-in-out';
})(CSSTransitionType || (exports.CSSTransitionType = CSSTransitionType = {}));
var BerRating;
(function (BerRating) {
  BerRating['A1'] = 'A1';
  BerRating['A2'] = 'A2';
  BerRating['A3'] = 'A3';
  BerRating['B1'] = 'B1';
  BerRating['B2'] = 'B2';
  BerRating['B3'] = 'B3';
  BerRating['C1'] = 'C1';
  BerRating['C2'] = 'C2';
  BerRating['C3'] = 'C3';
  BerRating['D1'] = 'D1';
  BerRating['D2'] = 'D2';
  BerRating['E1'] = 'E1';
  BerRating['E2'] = 'E2';
  BerRating['F'] = 'F';
  BerRating['G'] = 'G';
  BerRating['SI_666'] = 'SI_666';
  BerRating['A1A2'] = 'A1A2';
  BerRating['A1A3'] = 'A1A3';
  BerRating['A1B1'] = 'A1B1';
  BerRating['A1B2'] = 'A1B2';
  BerRating['A1B3'] = 'A1B3';
  BerRating['A1C1'] = 'A1C1';
  BerRating['A1C2'] = 'A1C2';
  BerRating['A1C3'] = 'A1C3';
  BerRating['A1D1'] = 'A1D1';
  BerRating['A1D2'] = 'A1D2';
  BerRating['A1E1'] = 'A1E1';
  BerRating['A1E2'] = 'A1E2';
  BerRating['A1F'] = 'A1F';
  BerRating['A1G'] = 'A1G';
  BerRating['A2A3'] = 'A2A3';
  BerRating['A2B1'] = 'A2B1';
  BerRating['A2B2'] = 'A2B2';
  BerRating['A2B3'] = 'A2B3';
  BerRating['A2C1'] = 'A2C1';
  BerRating['A2C2'] = 'A2C2';
  BerRating['A2C3'] = 'A2C3';
  BerRating['A2D1'] = 'A2D1';
  BerRating['A2D2'] = 'A2D2';
  BerRating['A2E1'] = 'A2E1';
  BerRating['A2E2'] = 'A2E2';
  BerRating['A2F'] = 'A2F';
  BerRating['A2G'] = 'A2G';
  BerRating['A3B1'] = 'A3B1';
  BerRating['A3B2'] = 'A3B2';
  BerRating['A3B3'] = 'A3B3';
  BerRating['A3C1'] = 'A3C1';
  BerRating['A3C2'] = 'A3C2';
  BerRating['A3D1'] = 'A3D1';
  BerRating['A3D2'] = 'A3D2';
  BerRating['A3E1'] = 'A3E1';
  BerRating['A3E2'] = 'A3E2';
  BerRating['A3F'] = 'A3F';
  BerRating['A3G'] = 'A3G';
  BerRating['B1B2'] = 'B1B2';
  BerRating['B1B3'] = 'B1B3';
  BerRating['B1C1'] = 'B1C1';
  BerRating['B1C2'] = 'B1C2';
  BerRating['B1C3'] = 'B1C3';
  BerRating['B1D1'] = 'B1D1';
  BerRating['B1D2'] = 'B1D2';
  BerRating['B1E1'] = 'B1E1';
  BerRating['B1E2'] = 'B1E2';
  BerRating['B1F'] = 'B1F';
  BerRating['B1G'] = 'B1G';
  BerRating['B2B3'] = 'B2B3';
  BerRating['B2C1'] = 'B2C1';
  BerRating['B2C2'] = 'B2C2';
  BerRating['B2C3'] = 'B2C3';
  BerRating['B2D1'] = 'B2D1';
  BerRating['B2D2'] = 'B2D2';
  BerRating['B2E1'] = 'B2E1';
  BerRating['B2E2'] = 'B2E2';
  BerRating['B2F'] = 'B2F';
  BerRating['B2G'] = 'B2G';
  BerRating['B3C1'] = 'B3C1';
  BerRating['B3C2'] = 'B3C2';
  BerRating['B3C3'] = 'B3C3';
  BerRating['B3D1'] = 'B3D1';
  BerRating['B3D2'] = 'B3D2';
  BerRating['B3E1'] = 'B3E1';
  BerRating['B3E2'] = 'B3E2';
  BerRating['B3F'] = 'B3F';
  BerRating['B3G'] = 'B3G';
  BerRating['C1C2'] = 'C1C2';
  BerRating['C1C3'] = 'C1C3';
  BerRating['C1D1'] = 'C1D1';
  BerRating['C1D2'] = 'C1D2';
  BerRating['C1E1'] = 'C1E1';
  BerRating['C1E2'] = 'C1E2';
  BerRating['C1F'] = 'C1F';
  BerRating['C1G'] = 'C1G';
  BerRating['C2C3'] = 'C2C3';
  BerRating['C2D1'] = 'C2D1';
  BerRating['C2D2'] = 'C2D2';
  BerRating['C2E1'] = 'C2E1';
  BerRating['C2E2'] = 'C2E2';
  BerRating['C2F'] = 'C2F';
  BerRating['C2G'] = 'C2G';
  BerRating['C3D1'] = 'C3D1';
  BerRating['C3D2'] = 'C3D2';
  BerRating['C3E1'] = 'C3E1';
  BerRating['C3E2'] = 'C3E2';
  BerRating['C3F'] = 'C3F';
  BerRating['C3G'] = 'C3G';
  BerRating['D1D2'] = 'D1D2';
  BerRating['D1E1'] = 'D1E1';
  BerRating['D1E2'] = 'D1E2';
  BerRating['D1F'] = 'D1F';
  BerRating['D1G'] = 'D1G';
  BerRating['D2E1'] = 'D2E1';
  BerRating['D2E2'] = 'D2E2';
  BerRating['D2F'] = 'D2F';
  BerRating['D2G'] = 'D2G';
  BerRating['E1E2'] = 'E1E2';
  BerRating['E1F'] = 'E1F';
  BerRating['E1G'] = 'E1G';
  BerRating['E2F'] = 'E2F';
  BerRating['E2G'] = 'E2G';
  BerRating['FG'] = 'FG';
})(BerRating || (exports.BerRating = BerRating = {}));
var PriceHistoryIndicatorDirection;
(function (PriceHistoryIndicatorDirection) {
  PriceHistoryIndicatorDirection['INCREASE'] = 'INCREASE';
  PriceHistoryIndicatorDirection['DECREASE'] = 'DECREASE';
})(
  PriceHistoryIndicatorDirection ||
    (exports.PriceHistoryIndicatorDirection = PriceHistoryIndicatorDirection =
      {}),
);
var ImageSizes;
(function (ImageSizes) {
  ImageSizes['size1440x960'] = 'size1440x960';
  ImageSizes['size1200x1200'] = 'size1200x1200';
  ImageSizes['size720x480'] = 'size720x480';
  ImageSizes['size680x392'] = 'size680x392';
  ImageSizes['size600x450'] = 'size600x450';
  ImageSizes['size400x300'] = 'size400x300';
  ImageSizes['size360x240'] = 'size360x240';
  ImageSizes['size320x280'] = 'size320x280';
  ImageSizes['size300x200'] = 'size300x200';
})(ImageSizes || (exports.ImageSizes = ImageSizes = {}));
var AdFeatureLevel;
(function (AdFeatureLevel) {
  AdFeatureLevel['BASIC'] = 'BASIC';
  AdFeatureLevel['STANDARD'] = 'STANDARD';
  AdFeatureLevel['LITE'] = 'LITE';
  AdFeatureLevel['PREMIUM'] = 'PREMIUM';
  AdFeatureLevel['FEATURED'] = 'FEATURED';
  AdFeatureLevel['SPOTLIGHT'] = 'SPOTLIGHT';
})(AdFeatureLevel || (exports.AdFeatureLevel = AdFeatureLevel = {}));
var AdFeatureLevelFull;
(function (AdFeatureLevelFull) {
  AdFeatureLevelFull['STANDARD'] = 'STANDARD';
  AdFeatureLevelFull['FEATURED'] = 'FEATURED';
  AdFeatureLevelFull['PREMIUM'] = 'PREMIUM';
  AdFeatureLevelFull['PREMIER_PARTNER_LITE'] = 'PREMIER_PARTNER_LITE';
  AdFeatureLevelFull['PREMIER_PARTNER_PREMIUM'] = 'PREMIER_PARTNER_PREMIUM';
  AdFeatureLevelFull['PREMIER_PARTNER_FEATURED'] = 'PREMIER_PARTNER_FEATURED';
})(
  AdFeatureLevelFull || (exports.AdFeatureLevelFull = AdFeatureLevelFull = {}),
);
var FeaturedLevelFullToRanking;
(function (FeaturedLevelFullToRanking) {
  FeaturedLevelFullToRanking['STANDARD'] = 'standard';
  FeaturedLevelFullToRanking['FEATURED'] = 'featured';
  FeaturedLevelFullToRanking['PREMIUM'] = 'premium';
  FeaturedLevelFullToRanking['PREMIER_PARTNER_LITE'] = 'advantageLite';
  FeaturedLevelFullToRanking['PREMIER_PARTNER_PREMIUM'] = 'advantagePremium';
  FeaturedLevelFullToRanking['PREMIER_PARTNER_FEATURED'] = 'advantageFeatured';
})(
  FeaturedLevelFullToRanking ||
    (exports.FeaturedLevelFullToRanking = FeaturedLevelFullToRanking = {}),
);
var SellerType;
(function (SellerType) {
  SellerType['PRIVATE'] = 'PRIVATE_USER';
  SellerType['UNBRANDED'] = 'UNBRANDED_AGENT';
  SellerType['BRANDED'] = 'BRANDED_AGENT';
})(SellerType || (exports.SellerType = SellerType = {}));
var SEARCH_URLS;
(function (SEARCH_URLS) {
  SEARCH_URLS['BUY'] = 'property-for-sale';
  SEARCH_URLS['RENT'] = 'property-for-rent';
  SEARCH_URLS['SHARE'] = 'sharing';
  SEARCH_URLS['NEW_HOMES'] = 'new-homes-for-sale';
  SEARCH_URLS['COMMERCIAL_RENT'] = 'commercial-properties-for-rent';
  SEARCH_URLS['COMMERCIAL_BUY'] = 'commercial-properties-for-sale';
  SEARCH_URLS['STUDENT_ACCOMMODATION_RENT'] = 'student-accommodation-for-rent';
  SEARCH_URLS['STUDENT_ACCOMMODATION_SHARE'] = 'student-accommodation-to-share';
  SEARCH_URLS['PARKING_BUY'] = 'parking-spaces-for-sale';
  SEARCH_URLS['PARKING_RENT'] = 'parking-spaces-for-rent';
  SEARCH_URLS['INTERNATIONAL_BUY'] = 'overseas-properties-for-sale';
  SEARCH_URLS['INTERNATIONAL_RENT'] = 'overseas-properties-for-rent';
  SEARCH_URLS['HOLIDAY_HOMES'] = 'holiday-homes';
})(SEARCH_URLS || (exports.SEARCH_URLS = SEARCH_URLS = {}));
var VT_SEARCH_URLS;
(function (VT_SEARCH_URLS) {
  VT_SEARCH_URLS['PROPERTY_PRICE_REGISTER'] =
    'valuation-tool-property-price-register';
  VT_SEARCH_URLS['RESIDENTIAL_RENT'] = 'valuation-tool-residential-rent';
  VT_SEARCH_URLS['RESIDENTIAL_SALES'] = 'valuation-tool-residential-sales';
  VT_SEARCH_URLS['COMMERCIAL'] = 'valuation-tool-commercial';
})(VT_SEARCH_URLS || (exports.VT_SEARCH_URLS = VT_SEARCH_URLS = {}));
var VT_SEARCH_URLS_V2;
(function (VT_SEARCH_URLS_V2) {
  VT_SEARCH_URLS_V2['PROPERTY_PRICE_REGISTER'] =
    'valuation-tool-v2-property-price-register';
  VT_SEARCH_URLS_V2['RESIDENTIAL_RENT'] = 'valuation-tool-v2-residential-rent';
  VT_SEARCH_URLS_V2['RESIDENTIAL_SALES'] =
    'valuation-tool-v2-residential-sales';
  VT_SEARCH_URLS_V2['COMMERCIAL'] = 'valuation-tool-v2-commercial';
})(VT_SEARCH_URLS_V2 || (exports.VT_SEARCH_URLS_V2 = VT_SEARCH_URLS_V2 = {}));
var AGENT_OFFERS;
(function (AGENT_OFFERS) {
  AGENT_OFFERS['RESIDENTIAL_SALES'] = 'offers/residential-for-sale';
  AGENT_OFFERS['COMMERCIAL_SALES'] = 'offers/commercial-for-sale';
})(AGENT_OFFERS || (exports.AGENT_OFFERS = AGENT_OFFERS = {}));
var LISTING_PAGE_BASE_URLS;
(function (LISTING_PAGE_BASE_URLS) {
  LISTING_PAGE_BASE_URLS['BUY'] = 'for-sale';
  LISTING_PAGE_BASE_URLS['RENT'] = 'for-rent';
  LISTING_PAGE_BASE_URLS['SHARE'] = 'share';
  LISTING_PAGE_BASE_URLS['NEW_HOMES'] = 'new-home-for-sale';
  LISTING_PAGE_BASE_URLS['COMMERCIAL_RENT'] = 'commercial-property-for-rent';
  LISTING_PAGE_BASE_URLS['COMMERCIAL_BUY'] = 'commercial-property-for-sale';
  LISTING_PAGE_BASE_URLS['PARKING_BUY'] = 'parking-space-for-sale';
  LISTING_PAGE_BASE_URLS['PARKING_RENT'] = 'parking-space-for-rent';
  LISTING_PAGE_BASE_URLS['INTERNATIONAL_BUY'] = 'overseas-property-for-sale';
  LISTING_PAGE_BASE_URLS['INTERNATIONAL_RENT'] = 'overseas-property-for-rent';
  LISTING_PAGE_BASE_URLS['HOLIDAY_HOMES'] = 'holiday-home';
})(
  LISTING_PAGE_BASE_URLS ||
    (exports.LISTING_PAGE_BASE_URLS = LISTING_PAGE_BASE_URLS = {}),
);
var VT_LISTING_PAGE_BASE_URLS;
(function (VT_LISTING_PAGE_BASE_URLS) {
  VT_LISTING_PAGE_BASE_URLS['PROPERTY_PRICE_REGISTER'] =
    'valuation-tool-property-price-register-property';
  VT_LISTING_PAGE_BASE_URLS['RESIDENTIAL_RENT'] =
    'valuation-tool-residential-rent-property';
  VT_LISTING_PAGE_BASE_URLS['RESIDENTIAL_SALES'] =
    'valuation-tool-residential-sales-property';
  VT_LISTING_PAGE_BASE_URLS['COMMERCIAL'] =
    'valuation-tool-commercial-property';
})(
  VT_LISTING_PAGE_BASE_URLS ||
    (exports.VT_LISTING_PAGE_BASE_URLS = VT_LISTING_PAGE_BASE_URLS = {}),
);
var HOMEPAGE_URLS;
(function (HOMEPAGE_URLS) {
  HOMEPAGE_URLS['BUY'] = '';
  HOMEPAGE_URLS['RENT'] = 'homes-to-rent';
  HOMEPAGE_URLS['SHARE'] = 'rooms-to-rent';
  HOMEPAGE_URLS['NEW_HOMES'] = 'new-build';
  HOMEPAGE_URLS['SELL'] = 'https://www.sell.daft.ie/';
  HOMEPAGE_URLS['COMMERCIAL'] = 'commercial-property';
  HOMEPAGE_URLS['STUDENT_ACCOMMODATION'] = 'student-accommodation';
})(HOMEPAGE_URLS || (exports.HOMEPAGE_URLS = HOMEPAGE_URLS = {}));
var PAGES;
(function (PAGES) {
  PAGES['HOME'] = 'index';
  PAGES['SEARCH'] = 'search';
  PAGES['LISTING'] = 'property';
  PAGES['MY_DAFT'] = 'mydaft';
  PAGES['VT_SEARCH'] = 'valuation-tool';
})(PAGES || (exports.PAGES = PAGES = {}));
var REALM_URLS;
(function (REALM_URLS) {
  REALM_URLS['DAFT'] = '';
  REALM_URLS['AGENTS'] = '/agents';
  REALM_URLS['AGENTSV2'] = '/agent-v2';
})(REALM_URLS || (exports.REALM_URLS = REALM_URLS = {}));
var SECTIONS;
(function (SECTIONS) {
  SECTIONS['BUY'] = 'residential-for-sale';
  SECTIONS['RENT'] = 'residential-to-rent';
  SECTIONS['SHARE'] = 'sharing';
  SECTIONS['COMMERCIAL_BUY'] = 'commercial-for-sale';
  SECTIONS['COMMERCIAL_RENT'] = 'commercial-to-rent';
  SECTIONS['STUDENT_ACCOMMODATION_RENT'] = 'student-accommodation-for-rent';
  SECTIONS['STUDENT_ACCOMMODATION_SHARE'] = 'student-accommodation-to-share';
  SECTIONS['PARKING_BUY'] = 'parking-for-sale';
  SECTIONS['PARKING_RENT'] = 'parking-to-rent';
  SECTIONS['NEW_HOMES'] = 'new-homes';
  SECTIONS['HOLIDAY_HOMES'] = 'holiday-homes';
  SECTIONS['INTERNATIONAL_BUY'] = 'international-for-sale';
  SECTIONS['INTERNATIONAL_RENT'] = 'international-to-rent';
  SECTIONS['VT_PROPERTY_PRICE_REGISTER'] =
    'valuation-tool-property-price-register';
  SECTIONS['VT_RESIDENTIAL_RENT'] = 'valuation-tool-residential-rent';
  SECTIONS['VT_RESIDENTIAL_SALES'] = 'valuation-tool-residential-sales';
  SECTIONS['VT_COMMERCIAL'] = 'valuation-tool-commercial';
})(SECTIONS || (exports.SECTIONS = SECTIONS = {}));
var SemanticButtonTypes;
(function (SemanticButtonTypes) {
  SemanticButtonTypes['button'] = 'button';
  SemanticButtonTypes['submit'] = 'submit';
  SemanticButtonTypes['reset'] = 'reset';
})(
  SemanticButtonTypes ||
    (exports.SemanticButtonTypes = SemanticButtonTypes = {}),
);
var NewButtonTypes;
(function (NewButtonTypes) {
  NewButtonTypes['PRIMARY'] = 'PRIMARY';
  NewButtonTypes['SECONDARY'] = 'SECONDARY';
  NewButtonTypes['TERTIARY'] = 'TERTIARY';
  NewButtonTypes['GHOST'] = 'GHOST';
})(NewButtonTypes || (exports.NewButtonTypes = NewButtonTypes = {}));
var NewButtonSizes;
(function (NewButtonSizes) {
  NewButtonSizes['SMALL'] = 'SMALL';
  NewButtonSizes['MEDIUM'] = 'MEDIUM';
  NewButtonSizes['LARGE'] = 'LARGE';
})(NewButtonSizes || (exports.NewButtonSizes = NewButtonSizes = {}));
var BidderStatus;
(function (BidderStatus) {
  BidderStatus['PENDING'] = 'PENDING';
  BidderStatus['APPROVED'] = 'APPROVED';
  BidderStatus['REJECTED'] = 'REJECTED';
  BidderStatus['DELETED'] = 'DELETED';
})(BidderStatus || (exports.BidderStatus = BidderStatus = {}));
var OfferStatus;
(function (OfferStatus) {
  OfferStatus['APPROVED'] = 'APPROVED';
  OfferStatus['WITHDRAWN'] = 'WITHDRAWN';
  OfferStatus['WON'] = 'WON';
})(OfferStatus || (exports.OfferStatus = OfferStatus = {}));
var Orientation;
(function (Orientation) {
  Orientation['VERTICAL'] = 'VERTICAL';
  Orientation['HORIZONTAL'] = 'HORIZONTAL';
})(Orientation || (exports.Orientation = Orientation = {}));
